const LANGUAGE = {
  SHELL: 'shell',
  JAVASCRIPT: 'javascript',
  GO: 'go',
  PYTHON: 'python',
  JAVA: 'java',
}


export {
  LANGUAGE
}